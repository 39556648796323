"use strict";

var AFApp = function () {
    var initialized = false;

    var initSmoothScroll = function () {
        if (initialized === true) {
            return;
        }

        if (typeof SmoothScroll === 'undefined') {
            return;
        }

        new SmoothScroll('a[data-kt-scroll-toggle][href*="#"]', {
            speed: 1000,
            speedAsDuration: true,
            offset: function (anchor, toggle) {
                // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
                // This example is a function, but you could do something as simple as `offset: 25`

                // An example returning different values based on whether the clicked link was in the header nav or not
                if (anchor.hasAttribute('data-kt-scroll-offset')) {
                    // var val = AFAUtils.getResponsiveValue(anchor.getAttribute('data-kt-scroll-offset'));

                    return val;
                } else {
                    return 0;
                }
            }
        });
    }

    var showPageLoading = function () {
        document.body.classList.add('page-loading');
        document.body.setAttribute('data-afa-app-page-loading', "on");
    }

    var hidePageLoading = function () {
        // CSS3 Transitions only after page load(.page-loading or .app-page-loading class added to body tag and remove with JS on page load)
        document.body.classList.remove('page-loading');
        document.body.removeAttribute('data-afa-app-page-loading');
    }

    var scriptsFn = async function () {

        if (typeof jQuery == 'undefined') {
            return;
        }

        $("#year-range").slider({
            range: true,
            min: 1990,
            max: 2024,
            step: 1,
            values: [1990, 2024],
            create: function () {
                // Initialize the handles with their values
                var handles = $("#year-range .ui-slider-handle");
                handles.eq(0).attr("data-value", $("#year-range").slider("values", 0));
                handles.eq(1).attr("data-value", $("#year-range").slider("values", 1));
            },
            slide: function (event, ui) {
                // Update the handles with their values when sliding
                var handles = $("#year-range .ui-slider-handle");
                handles.eq(0).attr("data-value", $("#year-range").slider("values", 0));
                handles.eq(1).attr("data-value", $("#year-range").slider("values", 1));
            }
        });

        // $("#mileage-range").slider({
        //     range: true,
        //     min: 0,
        //     max: 100000,
        //     step: 1,
        //     values: [0, 100000],
        //     create: function () {
        //         // Initialize the handles with their values
        //         var handles = $("#mileage-range .ui-slider-handle");
        //         handles.eq(0).attr("data-value", $("#mileage-range").slider("values", 0));
        //         handles.eq(1).attr("data-value", $("#mileage-range").slider("values", 1));
        //     },
        //     slide: function (event, ui) {
        //         // Update the handles with their values when sliding
        //         var handles = $("#mileage-range .ui-slider-handle");
        //         handles.eq(0).attr("data-value", $("#mileage-range").slider("values", 0));
        //         handles.eq(1).attr("data-value", $("#mileage-range").slider("values", 1));
        //     }
        // });

        $("#mileage-range").slider({
            range: true,
            min: 0,
            max: 100000,
            step: 1,
            values: [0, 100000],
            create: function () {
                var handles = $("#mileage-range .ui-slider-handle");
                handles.eq(0).attr("data-value", $("#mileage-range").slider("values", 0));
                handles.eq(1).attr("data-value", $("#mileage-range").slider("values", 1));
            },
            slide: function (event, ui) {
                var handles = $("#mileage-range .ui-slider-handle");
                handles.eq(0).attr("data-value", $("#mileage-range").slider("values", 0));
                handles.eq(1).attr("data-value", $("#mileage-range").slider("values", 1));
            } 
        });
        

        $("#engine-range").slider({
            range: true,
            min: 660,
            max: 15000,
            step: 1,
            values: [660, 15000],
            create: function () {
                // Initialize the handles with their values
                var handles = $("#engine-range .ui-slider-handle");
                handles.eq(0).attr("data-value", $("#engine-range").slider("values", 0));
                handles.eq(1).attr("data-value", $("#engine-range").slider("values", 1));
            },
            slide: function (event, ui) {
                // Update the handles with their values when sliding
                var handles = $("#engine-range .ui-slider-handle");
                handles.eq(0).attr("data-value", $("#engine-range").slider("values", 0));
                handles.eq(1).attr("data-value", $("#engine-range").slider("values", 1));
            }
        });

        $(window).on('scroll',function () {
            if ($(this).scrollTop() > 50) {
                $("#scroll").fadeIn();
            } else {
                $("#scroll").fadeOut();
            }
        });

        $("#scroll").on('click', function () {
            $("html, body").animate({ scrollTop: 0 }, 900);
            return false;
        });
    }

    return {
        init: function () {
            initSmoothScroll();

            scriptsFn();
            console.log("Application Initialized");
            initialized = true;
        },
        showPageLoading: function () {
            showPageLoading();
        },
        hidePageLoading: function () {
            hidePageLoading();
        },
    }

}(jQuery);


// Declare KTApp for Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = AFApp;
}